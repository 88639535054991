// React
import React from "react";
import esrilogo from "../../styles/images/Logo_esri.png";
import twitterlogo from "../../styles/images/twitter.svg";
import facebooklogo from "../../styles/images/facebook-8.svg";
import linkedinlogo from "../../styles/images/linkedin-1.svg";

//make an new window from the current href and add query paramters for sharing.
const Header = ({ view, isMapCir }) => {
  const shareMedia = evt => {
    let year = "2012";
    let visibleLayer = view.allLayerViews.items.find(i => i.visible === true);
    if (visibleLayer) {
      year = visibleLayer.layer.title;
    }

    let href = `${evt.currentTarget.href}%3fyear%3d${year}%26x%3d${view.center.x}%26y%3d${view.center.y}%26l%3d${view.zoom}%26isMapCir%3d${isMapCir}`;
    window.open(href, "_blank");
    evt.preventDefault();
  };

  return (
    <section className={"secSkewedStyle"}>
      <div className={"devHeaderRowStyle"}>
        <div className={"devHeaderColStyle"} align="left">
          <picture>
            <img src={esrilogo} className={"imgHeaderLogo"} href="#" alt="" />
          </picture>
        </div>
        <div className={"divHeaderTextStyle devHeaderColStyle"}>
          <span
            className={"spanHeaderTitle"}
            long_txt="Bekijk de foto van toen, in rood of in groen"
            short_txt="Bekijk de foto van toen"
          ></span>
        </div>
        {/* https://codepen.io/adamcoti/pen/woGwym Social Media Sharing: HTML Links Generator*/}
        <div align="right" className={"div_media"}>
          <a
            href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.luchtfototijdreis.nl"
            target="_blank"
            rel="noopener noreferrer"
            onClick={shareMedia}
          >
            <img
              src={facebooklogo}
              className="social_mediaicon"
              href="#"
              alt=""
            />
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Jaren%20aan%20luchtfoto%E2%80%99s%3A%20Bekijk%20Nederland%20vanuit%20de%20lucht%20op&hashtags=Esri,ArcGis,luchtfototijdreis&url=https%3A%2F%2Fwww.luchtfototijdreis.nl"
            target="_blank"
            rel="noopener noreferrer"
            onClick={shareMedia}
          >
            <img
              src={twitterlogo}
              className="social_mediaicon"
              href="#"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/shareArticle?mini=true&title=Luchtfototijdreis&url=https%3A%2F%2Fwww.luchtfototijdreis.nl"
            target="_blank"
            rel="noopener noreferrer"
            onClick={shareMedia}
          >
            <img
              src={linkedinlogo}
              className="social_mediaicon"
              href="#"
              alt=""
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
