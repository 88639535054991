import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
//https://material-ui.com/api/slider/

const DiscreteSlider = ({
  currentlayers,
  initYear = null,
  initPlay = false
}) => {
  const [isActive, setIsActive] = useState(initPlay);

  //Make the markers from the layers inside the view [{value: 0, label: "2012"}] for the slider.
  var tileLayers = currentlayers.filter(
    it => it.type === "tile" && it.id !== "reference"
  );
  var countLayers = tileLayers.length;
  var step = 100 / (countLayers - 1);
  var marks = [];
  tileLayers.forEach((layer, i) =>
    marks.push({ value: step * i, label: layer.title })
  );

  //if the query parameter year is given then find the value of the mark.
  let initValue = 0;
  if (initYear) {
    let mark = marks.find(mark => mark.label === initYear);
    if (mark) initValue = mark.value;
  }

  const [currentValue, setValue] = React.useState(initValue);

  //lookup the value and get the year as string.
  const valueLabelFormat = value => {
    let mark = marks.find(mark => mark.value === value);
    if (mark) return mark.label;
    return "";
  };

  //Change slider, turn all layer visible false and current year on true.
  const handleSliderChange = (evt, newValue) => {
    if (currentValue === newValue) return;
    setValue(newValue);
    let year = valueLabelFormat(newValue);
    currentlayers.forEach(layer => {
      if (layer.title === year) {
        layer.visible = true;
      } else {
        layer.visible = false;
      }
    });
  };

  const playTimeSlider = evt => {
    if (evt.currentTarget.childNodes[0].classList.contains("esri-icon-pause")) {
      evt.currentTarget.childNodes[0].classList.remove("esri-icon-pause");
      evt.currentTarget.childNodes[0].classList.add("esri-icon-play");
      setIsActive(false);
    } else {
      evt.currentTarget.childNodes[0].classList.remove("esri-icon-play");
      evt.currentTarget.childNodes[0].classList.add("esri-icon-pause");
      setIsActive(true);
    }
  };

  useEffect (() => {
    let interval = null;
    if (isActive) {
      let mark = marks[0];
      let index = marks.findIndex(mark => mark.value === currentValue);
      if (marks.length > index + 1) mark = marks[index + 1];

      interval = setInterval(() => {
        setValue(mark.value);
        currentlayers.forEach(layer => {
          if (layer.title === mark.label) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
        });
      }, 3000);
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, currentValue, marks, currentlayers]);

  return (
    <section className={"secSliderSkewedStyle"}>
      <div className={"sliderStyle"}>
        <div
          className={"divButtonPlayStyle"}
          onClick={playTimeSlider}
          title="Automatisch afspelen"
        >
          <span className="esri-icon esri-icon-play big-icon"></span>
        </div>
        <Slider
          track={false}
          value={typeof currentValue === "number" ? currentValue : 0}
          valueLabelFormat={valueLabelFormat}
          step={null}
          valueLabelDisplay="on"
          marks={marks}
          onChange={handleSliderChange}
        />
      </div>
    </section>
  );
};

export default DiscreteSlider;
