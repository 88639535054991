import React, { Component } from "react";
import ReactDOM from "react-dom";
import { loadModules } from "esri-loader";
//import { esriOptions } from "./config";
//import "./App.css";
import Header from "./components/Header";
import DiscreteSlider from "./components/DiscreteSlider";
import Button from "calcite-react/Button";
import Modal, { ModalActions } from "calcite-react/Modal";
import { CalciteP, CalciteH1 } from "calcite-react/Elements";
//Replace all format("woff2") to format("embedded-opentype").
//import "./theme/green-theme/main.css";
//import "./theme/light-red/main.scss";
//require("./theme/green-theme/main.css");

//import "./theme/red-theme/main.css";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      openInfoModal: false,
      referenceLayerPortal: null,
      mapPortal: null,
      mapPortalCir: null,
      isMapCir: false
    };
  }

  componentDidMount = async () => {
    try {
      const [
        Map,
        WebMap,
        Locator,
        MapView,
        Search,
        Zoom,
        Lod,
        TileLayer,
        Point,
        Compass,
        VectorTileLayer
      ] = await loadModules(
        [
          "esri/Map",
          "esri/WebMap",
          "esri/tasks/Locator",
          "esri/views/MapView",
          "esri/widgets/Search",
          "esri/widgets/Zoom",
          "esri/layers/support/LOD",
          "esri/layers/TileLayer",
          "esri/geometry/Point",
          "esri/widgets/Compass",
          "esri/layers/VectorTileLayer"
        ]
        //esriOptions
      );
      var year, x, y, l, isMapCir, play;
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("year")) {
        year = urlParams.get("year");
      }
      if (urlParams.has("x")) {
        x = Number(urlParams.get("x"));
      }
      if (urlParams.has("y")) {
        y = Number(urlParams.get("y"));
      }
      if (urlParams.has("l")) {
        l = Number(urlParams.get("l"));
      }
      if (urlParams.has("isMapCir")) {
        isMapCir = urlParams.get("isMapCir") === "true";
      }
      if (urlParams.has("play")) {
        play = urlParams.get("play") === "true";
      }

      //define the webmap with an portalItem id (luchtfoto's)
      const mapPortal = new WebMap({
        portalItem: {
          id: "6a8c4ce34fbe460d824e639e095a2128"
        }
      });

      //define the webmap with an portalItem id (luchtfoto's Cir)
      const mapPortalCir = new WebMap({
        portalItem: {
          id: "efc4da390d4f4374977dd84e8fdda381"
        }
      });

      //load the webmap and filter based on TiledMapServiceLayers
      await mapPortal.load();
      var layersfiltered = await mapPortal.layers.filter(
        layer => layer.operationalLayerType === "ArcGISTiledMapServiceLayer"
      );

      this.setState({
        mapPortal: mapPortal,
        mapPortalCir: mapPortalCir
      });

      //Sort the layer based on title. All titles are defined as year number.
      //Turn on the visibility on the first layer.
      var layersSorted = await layersfiltered.sort((a, b) => parseInt(a.title, 10) - parseInt(b.title, 10));
      var layers;
      if (year) {
        layers = await layersSorted.map(l => {
          if (l.title === year) {
            l.visible = true;
          } else {
            l.visible = false;
          }
          l.id = l.title;
          return l;
        });
      } else {
        layers = await layersSorted.map((l, i) => {
          if (i === 0) {
            l.visible = true;
          } else {
            l.visible = false;
          }
          l.id = l.title;
          return l;
        });
      }

      // var referenceLayerPortal = new TileLayer({
      //   url:
      //     "https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Donkergrijze_Canvas_Referentie/MapServer",
      //   id: "reference",
      //   visible: false
      // });
      var referenceLayerPortal = new VectorTileLayer({
        portalItem: {
          id: "d3d536e451854a22afca9b350f8d786a"
        },
        id: "reference",
        visible: true
      });

      this.setState({
        referenceLayerPortal: referenceLayerPortal
      });

      var map = new Map({
        layers: layers,
        basemap: {
          referenceLayers: [referenceLayerPortal]
        }
      });

      var jsonlods = [
        { level: 0, resolution: 3251.206502413005, scale: 12288000 },
        { level: 1, resolution: 1625.6032512065026, scale: 6144000 },
        { level: 2, resolution: 812.8016256032513, scale: 3072000 },
        { level: 3, resolution: 406.40081280162565, scale: 1536000 },
        { level: 4, resolution: 203.20040640081282, scale: 768000 },
        { level: 5, resolution: 101.60020320040641, scale: 384000 },
        { level: 6, resolution: 50.800101600203206, scale: 192000 },
        { level: 7, resolution: 25.400050800101603, scale: 96000 },
        { level: 8, resolution: 12.700025400050801, scale: 48000 },
        { level: 9, resolution: 6.350012700025401, scale: 24000 },
        { level: 10, resolution: 3.1750063500127004, scale: 12000 },
        { level: 11, resolution: 1.5875031750063502, scale: 6000 },
        { level: 12, resolution: 0.7937515875031751, scale: 3000 },
        { level: 13, resolution: 0.39687579375158755, scale: 1500 },
        { level: 14, resolution: 0.19843789687579377, scale: 750 },
        { level: 15, resolution: 0.09921894843789689, scale: 375 },
        { level: 16, resolution: 0.04960947421894844, scale: 187.5 }
      ];
      var lods = jsonlods.map(l => Lod.fromJSON(l));

      //define the MapView with custom lods.
      var view = new MapView({
        container: "mapContainer",
        map: map,
        constraints: {
          lods: lods
        }
      });

      view.ui.components = ["attribution"];

      this.setState({
        map,
        view,
        layers,
        status: "loaded",
        infoDescription: mapPortal.portalItem.description
      });

      await view.when();
      //Wait for all the layers are loaded and then create the Slider on the document.
      var promises = [];
      layers.forEach(l => promises.push(l.when()));
      await Promise.all(promises);

      //if query parameters x and y is given then goto that point, else goto map extend from the webmap.
      if (x && y && l) {
        //?year=2017&x=229234.85927355892&y=413189.67082550225&l=11&isMapCir=true
        var pt = new Point({
          x: x,
          y: y,
          spatialReference: view.spatialReference.wkid
        });
        await view.goTo({
          center: pt,
          zoom: l
        });
      } else {
        view.goTo(
          mapPortal.initialViewProperties.viewpoint.targetGeometry.extent
        );
      }
      //
      // Top Left widgets
      //

      const compass = new Compass({
        view: view
      });

      // adds the compass to the top left corner of the MapView
      view.ui.add(compass, "top-left");

      const infoButton = this.createInfoButton();
      view.ui.add(infoButton, "top-left");

      const referenceLayerButton = this.createReferenceLayerButton();
      view.ui.add(referenceLayerButton, "top-left");

      const luchtFotoButton = this.createLuchtFotoButton();
      view.ui.add(luchtFotoButton, "top-left");

      //
      // Top Right widgets
      //

      // create a layerlist with expand widget and add to the view
      // var layerList = new LayerList({
      //   view: view
      // });
      // const llExpand = new Expand({
      //   view: view,
      //   content: layerList,
      //   expanded: false
      // });
      // view.ui.add(llExpand, "top-right");

      const search = new Search({
        view: this.state.view,
        popupEnabled: this.state.view.width > 768 ? true : false,
        sources: [
          {
            locator: new Locator({
              url:
                "https://geocoder.arcgisonline.nl/arcgis/rest/services/Geocoder_NL_RD/GeocodeServer"
            }),
            singleLineFieldName: "SingleLine",
            name: "Esri Nederland Geocoder",
            placeholder: "Zoek op plaats",
            maxResults: 3,
            maxSuggestions: 8,
            suggestionsEnabled: true,
            minSuggestCharacters: 0
          }
        ],
        includeDefaultSources: false
      });
      this.state.view.ui.add(search, "top-right");

      view.watch("widthBreakpoint", newVal => {
        if (newVal === "xsmall") {
          search.popupEnabled = false;
        } else if (newVal === "small") {
          search.popupEnabled = false;
        } else if (view.ui.components.length === 1) {
          search.popupEnabled = true;
        }
      });

      const zoomWidget = new Zoom({
        view: view
      });

      view.ui.add(zoomWidget, {
        position: "top-right"
      });

      //if query parameters isMapCir is true load infrared layers.
      if (isMapCir === true) {
        this.toggelLuchtFoto(null, year, play);
      } else {
        ReactDOM.render(
          <DiscreteSlider
            currentlayers={layers}
            initYear={year}
            initPlay={play}
          />,
          document.getElementById("discreteSlider")
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  createInfoButton = () => {
    // Create a div and let it inherit the esri style from the js-api
    const divInfo = document.createElement("div");
    divInfo.classList = "esri-widget--button esri-widget esri-interactive";
    divInfo.onclick = this.showInfoModal.bind(this);
    divInfo.title = "Meer informatie over deze app";

    // Create an icon with a span element
    const spanInfo = document.createElement("span");
    spanInfo.classList = "esri-icon esri-icon-description";
    divInfo.appendChild(spanInfo);
    return divInfo;
  };

  createReferenceLayerButton = () => {
    const divReferenceLayer = document.createElement("div");
    divReferenceLayer.classList =
      "esri-widget--button esri-widget esri-interactive";
    divReferenceLayer.onclick = this.toggelReferenceLayer.bind(this);
    divReferenceLayer.title = "Toon of verberg de referentielaag";
    const spanReferenceLayer = document.createElement("span");
    spanReferenceLayer.classList = "esri-icon esri-icon-checkbox-checked";
    divReferenceLayer.appendChild(spanReferenceLayer);
    return divReferenceLayer;
  };

  createLuchtFotoButton = () => {
    const divLuchtFoto = document.createElement("div");
    divLuchtFoto.classList = "esri-widget--button esri-widget esri-interactive";
    divLuchtFoto.onclick = this.toggelLuchtFoto.bind(this);
    divLuchtFoto.title = "Wissel naar luchtfoto of infrarood-beeld";
    const spanLuchtFoto = document.createElement("span");
    spanLuchtFoto.classList = "esri-icon esri-icon-media";
    divLuchtFoto.appendChild(spanLuchtFoto);
    return divLuchtFoto;
  };

  showInfoModal = () => {
    this.setState({ openInfoModal: true });
  };

  toggelLuchtFoto = async (evt, year = null, play = false) => {
    ReactDOM.unmountComponentAtNode(document.getElementById("discreteSlider"));

    const style = document.getElementById("themeLink");

    if (!this.state.isMapCir && !this.state.mapPortalCir.loaded) {
      style.href = "red.css";
      await this.state.mapPortalCir.load();
      let layersCirfiltered = await this.state.mapPortalCir.layers.filter(
        layer => layer.operationalLayerType === "ArcGISTiledMapServiceLayer"
      );

      //Sort the layer based on title. All titles are defined as year number.
      //Turn on the visibility on the first layer.
      let layersCirSorted = await layersCirfiltered.sort(l => l.title);
      let layersCir = null;
      if (year) {
        layersCir = await layersCirSorted.map(l => {
          if (l.title === year) {
            l.visible = true;
          } else {
            l.visible = false;
          }
          l.id = l.title;
          return l;
        });
      } else {
        layersCir = await layersCirSorted.map((l, i) => {
          if (i === 0) {
            l.visible = true;
          } else {
            l.visible = false;
          }
          l.id = l.title;
          return l;
        });
      }
      this.setState({
        layersCir: layersCir,
        isMapCir: true
      });
      this.state.map.removeAll();
      this.state.map.addMany(layersCir);
      let promisesCir = [];
      layersCir.forEach(l => promisesCir.push(l.when()));
      await Promise.all(promisesCir);
      ReactDOM.render(
        <DiscreteSlider
          currentlayers={layersCir}
          initYear={year}
          initPlay={play}
        />,
        document.getElementById("discreteSlider")
      );
      this.setState({
        isMapCir: true
      });
    } else if (!this.state.isMapCir) {
      style.href = "red.css";
      this.state.map.removeAll();
      this.state.map.addMany(this.state.layersCir);
      ReactDOM.render(
        <DiscreteSlider currentlayers={this.state.layersCir} />,
        document.getElementById("discreteSlider")
      );
      this.setState({
        isMapCir: true
      });
    } else {
      style.href = "";
      this.state.map.removeAll();
      this.state.map.addMany(this.state.layers);
      ReactDOM.render(
        <DiscreteSlider currentlayers={this.state.layers} />,
        document.getElementById("discreteSlider")
      );
      this.setState({
        isMapCir: false
      });
    }
  };

  toggelReferenceLayer = async evt => {
    if (
      evt.currentTarget.childNodes[0].classList.contains(
        "esri-icon-checkbox-unchecked"
      )
    ) {
      evt.currentTarget.childNodes[0].classList.remove(
        "esri-icon-checkbox-unchecked"
      );
      evt.currentTarget.childNodes[0].classList.add(
        "esri-icon-checkbox-checked"
      );
      this.state.referenceLayerPortal.visible = true;
    } else {
      evt.currentTarget.childNodes[0].classList.remove(
        "esri-icon-checkbox-checked"
      );
      evt.currentTarget.childNodes[0].classList.add(
        "esri-icon-checkbox-unchecked"
      );
      this.state.referenceLayerPortal.visible = false;
    }
  };

  parseHtmlContent = htmlContent => {
    const result = document.createElement("div");
    result.innerHTML = htmlContent;
    return result;
  };

  onReu;

  render() {
    return (
      <div className="App">
        <div id="mapContainer" />
        <div id="swipeContainer" />
        <Header view={this.state.view} isMapCir={this.state.isMapCir} />
        <div id="discreteSlider" />
        <Modal
          open={this.state.openInfoModal}
          parentSelector={() => document.getElementById("root")}
          overlayStyle={{ position: "absolute" }}
          dialogStyle={{ width: "90%" }}
          appElement={document.body}
          onRequestClose={() => {
            this.setState({ openInfoModal: false });
          }}
        >
          <CalciteH1>Informatie</CalciteH1>
          <CalciteP
            dangerouslySetInnerHTML={{ __html: this.state.infoDescription }}
          ></CalciteP>
          <ModalActions>
            <Button
              green
              onClick={() => {
                this.setState({ openInfoModal: false });
              }}
            >
              Sluiten
            </Button>
          </ModalActions>
        </Modal>
      </div>
    );
  }
}
